
import { defineComponent, onMounted, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDataTable.vue";
import { Sort } from "@/components/kt-datatable//table-partials/models";
import axios from "axios";
const listData = ref();
const limit = ref(6);
const page = ref(0);
const orderBy = ref(1);
const sortBy = ref(1);
const categories = ref([]);
const totalPage = ref(0);
const filterForm = ref({
  category: "",
  status: "",
  orderBy: "3",
  search: "",
});

export default defineComponent({
  name: "exam-history",
  components: {
    Datatable,
  },
  data() {
    return {
      categoryList: [],
      imageUrl: process.env.VUE_APP_IMG_URL,
    };
  },
  setup() {
    const tableHeader = ref([
      {
        columnName: "รูปปก",
        columnLabel: "thumbnailsPath",
        sortEnabled: false,
        columnWidth: 100,
      },
      {
        columnName: "ชื่อข้อสอบ",
        columnLabel: "title",
        sortEnabled: false,
        columnWidth: 200,
      },
      {
        columnName: "รหัส",
        columnLabel: "examCode",
        sortEnabled: false,
        columnWidth: 200,
      },
      {
        columnName: "ประเภท",
        columnLabel: "examType",
        sortEnabled: false,
        columnWidth: 200,
      },
      {
        columnName: "วันที่สอบ",
        columnLabel: "createDate",
        sortEnabled: false,
        columnWidth: 100,
      },
      {
        columnName: "ครั้งที่สอบ",
        columnLabel: "round",
        sortEnabled: false,
        columnWidth: 100,
      },
      {
        columnName: "จำนวนข้อ",
        columnLabel: "totalQuestion",
        sortEnabled: true,
        columnWidth: 100,
      },
      {
        columnName: "คะแนนเต็ม",
        columnLabel: "totalScore",
        sortEnabled: false,
        columnWidth: 100,
      },
      {
        columnName: "คะแนนที่ได้",
        columnLabel: "score",
        sortEnabled: false,
        columnWidth: 100,
      },
      {
        columnName: "สถานะ",
        columnLabel: "status",
        sortEnabled: false,
        columnWidth: 100,
      },
    ]);
    const selectedIds = ref<Array<number>>([]);
    const pages = ref();
    const perpages = ref(25);
    const tableData = ref([]);
    const initCustomers = ref([]);
    const orderByTable = ref(2);
    const sortTable = ref(1);
    onMounted(() => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getDataTable();
    });
    const search = ref<string>("");
    const searchItems = (event) => {
      search.value = event.target.value;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getDataTable();
    };
    const sort = (sort: Sort) => {
      // const reverse: boolean = sort.order === "asc";
      orderByTable.value = sort.order == "asc" ? 1 : 2;
      if (sort.label == "createDate") {
        sortTable.value = 1;
      } else if (sort.label == "startDate") {
        sortTable.value = 2;
      } else if (sort.label == "endDate") {
        sortTable.value = 3;
      } else if (sort.label == "code") {
        sortTable.value = 4;
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getDataTable();
    };
    const onItemSelect = (selectedItems: Array<number>) => {
      selectedIds.value = selectedItems;
    };
    const pageChange = (page: number) => {
      pages.value = page;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getDataTable();
    };
    const perPageChange = (perpage: number) => {
      perpages.value = perpage;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getDataTable();
    };
    const getDataTable = () => {
      let sizeContents = perpages.value;
      let keyWord = "";
      let page = 0;
      // let startDate = "";
      // let endDate = "";
      // let sort = sortTable.value;
      // let orderBy = orderByTable.value;
      if (pages.value != undefined) {
        page = pages.value - 1;
      }
      if (perpages.value != undefined) {
        sizeContents = perpages.value;
      }
      if (search.value != undefined) {
        keyWord = search.value;
      }
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getExamScore?sizeContents=" +
            sizeContents +
            "&page=" +
            page +
            "&userId=" +
            localStorage.getItem("u_id") +
            "&examType=0",
          {
            headers: { token: localStorage.getItem("id_token") },
          }
        )
        .then((response) => {
          tableData.value = response.data.data.content;
          // console.log("getExamScore");
          // console.log(tableData.value);
          tableData.value["totalElements"] = response.data.data.totalElements;
          initCustomers.value.splice(
            0,
            tableData.value.length,
            ...tableData.value
          );
        })
        .catch((error) => {
          // console.log(error);
          // router.push({ name: "sign-in" });
        });
    };

    return {
      tableData,
      tableHeader,
      search,
      searchItems,
      selectedIds,
      sort,
      getDataTable,
      onItemSelect,
      pageChange,
      perPageChange,
      listData,
      limit,
      page,
      orderBy,
      sortBy,
      categories,
      totalPage,
      filterForm,
    };
  },
  methods: {
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
    removeStyle(html) {
      const regex = /(style=".+?")/gm;
      const str = html;
      const subst = ``;
      const result = str.replace(regex, subst);
      return result;
    },
    setAltImg(event) {
      event.target.src = "media/image-app/no-image.svg";
    },
    /* eslint-disable */
    renderCategory(list) {
      let data: any = [];
      for (const looplist of list) {
        data.push(looplist[0].data);
      }
      if (data.length > 0) {
        return data.join(",");
      } else {
        return "-";
      }
    },
    renderStatus(status) {
      if (status == 1 || status == 2) {
        return "<span style='color:#FFE454'>เรียนต่อ</span>";
      } else if (status == 3) {
        return "<span style='color:#42FB91'>จบหลักสูตร</span>";
      } else if (status == 4) {
        return "<span style='color:#F998A2'>ไม่ผ่านหลักสูตร</span>";
      } else if (status == 5) {
        return "<span style='color:#ECAE51'>หมดเวลาเรียน</span>";
      } else {
        return "-";
      }
    },
  },
  async mounted() {
    const category = await axios.get(
      process.env.VUE_APP_API_URL + "/category?categoryType=exam",
      { headers: {
        token: localStorage.getItem("id_token")
            ? localStorage.getItem("id_token")
            : localStorage.getItem("tokenGuest"),
      } }
    );
    // console.log(category);
    this.categoryList = category.data.data;
  },
});
